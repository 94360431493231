import {CeleryIcon, EggIcon, FishIcon, GlutenIcon, LupinIcon, MilkIcon, MolluscIcon, MustardIcon, NutsIcon, PeanutIcon, SesameIcon, SoyaIcon, SulphiteIcon}  from 'react-allergens';

const getIcon = slug => {
    switch (slug) {
      case 'celery':
        return <CeleryIcon width={20} height={20}/>
      case 'egg':
      case 'eggs':
        return <EggIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene huevos"></EggIcon>
      case 'fish':
        return <FishIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene pescado"/>
      case 'gluten':
        return <GlutenIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene gluten"/>
      case 'lupin':
        return <LupinIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene lupin"/>
      case 'milk':
        return <MilkIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene productos lácteos"/>
      case 'mollusc':
      case 'crab':
      case 'crabs':
        return <MolluscIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene moluscos"/>
      case 'mustard':
        return <MustardIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene mostaza"/>
      case 'nuts':
      case 'peanuts':
      case 'nut':
      case 'peanut':
        return <NutsIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene frutos secos"/>
      case 'sesame':
        return <SesameIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene sésamo"/>
      case 'soya':
        return <SoyaIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene soja"/>
      case 'sulphite':
      case 'ex':
        return <SulphiteIcon width={20} height={20} data-tooltip-id="1" data-tooltip-content="Contiene sulfitos"/>
      default:
        return <></>
    }
}
const formatPrice = price => {
  const priceArray = String(price).split('.');

  return <p>
    <span className="p__cormorant">{priceArray[0]}</span><span className="p__cormorant__subtitle">{!!priceArray[1] ? '.' + priceArray[1] : ''} €</span></p>
}

export {getIcon, formatPrice};