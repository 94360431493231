
import {formatPrice, getIcon} from '../../utils/utils'
import SubHeading from '../SubHeading/SubHeading';
const MenuItemWine = ({brand, wines}) => {
    return (  <div className="app__menuitem w-full my-4 mx-0">
  <div className="app__menuitem-head">
    <div className="app__menuitem-name">
      <p className="p__cormorant" style={{ color: '#ff5c5c' }}>{brand}</p>
    </div>
<div>
{wines.map(wine => {
    return (
        <p className="m-0 font-CormorantUpright  leading-7 text-base font-bold tracking-wider">
            <span className="text-primary-crimson">{wine.name}</span>
            <span className="w-1/6 h-[1px] bg-white mx-4 my-0" />

            {!!wine.prices[0] && <span className="text-yellow-200">{wine.prices[0]} €/copa</span>}
            {!!wine.prices[0] && <span className="w-1/6 h-[1px] bg-white mx-4 my-0" />}

            <span className="text-2xl text-[#ff5c5c]">{wine.prices[1]} €</span>


        </p>
    )
})}
</div>
  
  </div>
  </div>);
  }

  export default MenuItemWine;