import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="cabecera app__wrapper section__padding font-CormorantUpright text-primary-golden" id="home">
    <div className="flex-1 w-full flex items-start justify-center flex-col">
      <SubHeading title="Bienvenido a Doña Jimena" />
      <h1 className="headtext__cormorant font-bold mb-4">Cocina de mercado rebosante de cariño</h1>
      <p className="p__opensans mb-4">Con carta y plato del día. De miércoles a domingo en Valdemorillo.</p>
      <a href="#carta"><button type="button" className="custom__button">Ver la carta</button></a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.jimena_logo} className='imagen_cabecera' alt="header_img" />
    </div>
  </div>
);

export default Header;
