import './Box.css';
import images from '../../constants/images';
import { getIcon } from '../../utils/utils';

const Box = ({title, subtitle, price, tags}) => (
    <div class="relative group">
      <div class="absolute -inset-2 bg-gradient-to-r from-amber-500 to-red-500 rounded-lg blur opacity-25 group-hover:opacity-50 transition duration-1000 group-hover:duration-200"></div>
      <div class="relative px-7 py-6 bg-primary-crimson ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
        
      <img
                    src={images.symbol}
                    className="h-auto w-8 sm:w-8 2xl:w-16"/>
        <div class="space-y-2">
        {!!title && <p class="text-primary-golden text-lg font-bold" >{title}{!!price && ' - ' + price + '€'}</p>}
        {!!subtitle && <p class="text-slate-800 text-base">{subtitle}</p>}
        <div className="app__menuitem-sub w-full mt-[0.2rem] flex">
      { [tags].flat().map(tag => getIcon(tag))}
    </div>
        </div>
      </div>
    </div>
)
export default Box;