import React from 'react';

import { SubHeading, MenuItem, MenuItemWine, Box } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';
const SpecialMenu = () => (
  <><div className="flex-col bg-[#000000] app__bg flex__center section__padding" id="carta">
    <div className="mb-8 m-auto flex flex-col justify-center items-center">
      <SubHeading title="En Valdemorillo, a 17 de marzo de 2023" />
      <h1 className="headtext__cormorant">Nuestra carta</h1>
    </div>

    {/* Página 1 */}
    <div className="app__specialMenu-menu w-full mt-8 mx-0 flex justify-center items-center flex-col lg:items-start lg:flex-row">

      <div className="app__specialMenu-menu_food  flex__center flex-one w-full flex-col">
        <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-crimson md:text-5xl">Entradas</p>
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {data.entradas.map((food, index) => (
            <MenuItem key={food.title + index} title={food.title} price={food.price} tags={food.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img w-full mt-4 mb-12 mx-0 lg:w-[410px] lg:mx-8 lg:my-0 2xl:w-[650px]">
        <img src={images.jimena_menu11} alt="menu__img" />

      </div>


      <div className="app__specialMenu-menu_drinks  flex__center flex-one w-full flex-col">
        <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Ensaladas</p>
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {data.ensaladas.map((ensala, index) => (
            <MenuItem key={ensala.title + index} title={ensala.title} price={ensala.price} tags={ensala.tags} />
          ))}
        </div>

        <div className="flex flex-col justify-between items-center space-y-4">
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">De cuchara</p>
          {data.deCuchara.map((elem, index) => (
            <MenuItem key={elem.title + index} title={elem.title} price={elem.price} tags={elem.tags} />
          ))}
        </div>
      </div>
    </div>
    {/* Página 2 */}
    <div className="app__specialMenu-menu w-full mt-8 mx-0 flex justify-center items-center flex-col lg:items-start lg:flex-row">

      <div className="app__specialMenu-menu_food  flex__center flex-one w-full flex-col">
        <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-crimson md:text-5xl">Carnes</p>
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {data.carnes.map((food, index) => (
            <MenuItem key={food.title + index} title={food.title} price={food.price} tags={food.tags} />
          ))}
        </div>
        <div className="flex flex-col justify-between items-center space-y-4">
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Especiales</p>
          {data.especiales.map((elem, index) => (
            <MenuItem key={elem.title + index} title={elem.title} price={elem.price} tags={elem.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img w-full mt-4 mb-12 mx-0 lg:w-[410px] lg:mx-8 lg:my-0 2xl:w-[650px]">
        <img src={images.jimena_menu22} alt="menu__img" />
      </div>


      <div className="app__specialMenu-menu_drinks  flex__center flex-one w-full flex-col">
        <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Pescados</p>
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {data.pescados.map((ensala, index) => (
            <MenuItem key={ensala.title + index} title={ensala.title} price={ensala.price} tags={ensala.tags} />
          ))}
        </div>
        <div className="flex flex-col justify-between items-center space-y-4">
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Especiales de Mar</p>
          {data.especialesMar.map((elem, index) => (
            <MenuItem key={elem.title + index} title={elem.title} price={elem.price} tags={elem.tags} />
          ))}
        </div>
      </div>
    </div>
    {/* Página 3 */}
    <div className="app__specialMenu-menu w-full mt-8 mx-0 flex justify-center items-center flex-col lg:items-start lg:flex-row">

      <div className="app__specialMenu-menu_food  flex__center flex-one w-full flex-col">
        <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-crimson md:text-5xl">Postres</p>
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {data.postres.map((food, index) => (
            <MenuItem key={food.title + index} title={food.title} price={food.price} tags={food.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img w-full mt-4 mb-12 mx-0 lg:w-[410px] lg:mx-8 lg:my-0 2xl:w-[650px]">
        <img src={images.jimena_menu31} alt="menu__img" />
      </div>
    </div>
    {/* Página 4 */}
  </div><div className="flex-col bg-[#2e0f0f] app__bg flex__center section__padding" id="vinos">
      <div className="mb-8 m-auto flex flex-col justify-center items-center">
        <h1 className="headtext__cormorant">Vinos</h1>
      </div>

      {/* Página 1 */}
      <div className="app__specialMenu-menu w-full mt-8 mx-0 flex justify-center items-center flex-col lg:items-start lg:flex-row">

        <div className="app__specialMenu-menu_food  flex__center flex-one w-full flex-col">
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-crimson md:text-5xl">Tintos</p>
          <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
            {data.vinos.tintos.map((wine, index) => (
              <MenuItemWine key={wine.brand + index} brand={wine.brand} wines={wine.wines} />
            ))}
          </div>
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Rosados</p>
          <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
            {data.vinos.rosados.map((wine, index) => (
              <MenuItemWine key={wine.brand + index} brand={wine.brand} wines={wine.wines} />
              ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_img w-full mt-4 mb-12 mx-0 lg:w-[410px] lg:mx-8 lg:my-0 2xl:w-[650px]">
          <img src={images.wine} alt="menu__img" />
        </div>


        <div className="app__specialMenu-menu_drinks  flex__center flex-one w-full flex-col">
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Blancos</p>
          <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
            {data.vinos.blancos.map((wine, index) => (
              <MenuItemWine key={wine.brand + index} brand={wine.brand} wines={wine.wines} />
              ))}
          </div>
          <p className="app__specialMenu-menu_heading font-CormorantUpright font-semibold text-4xl leading-9 tracking-wider text-primary-white md:text-5xl">Semidulces</p>
          <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
            {data.vinos.semidulces.map((wine, index) => (
              <MenuItemWine key={wine.brand + index} brand={wine.brand} wines={wine.wines} />
              ))}
          </div>
        </div>
        
      </div>
    </div></>
);

export default SpecialMenu;
