import React from 'react';
import './MenuItem.css';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import {formatPrice, getIcon} from '../../utils/utils'



const MenuItem = ({ title, price, tags }) => (
  <div className="app__menuitem w-full my-4 mx-0 flex flex-col">
      <Tooltip id="1"></Tooltip>
    <div className="app__menuitem-head flex justify-between items-center">
      <div className="app__menuitem-name w-4/6">
        <p className="p__cormorant" style={{ color: '#ff5c5c' }}>{title}</p>
      </div>
      <div className="w-1/6 h-[1px] bg-white mx-4 my-0" />
      <div className="w-1/6 text-primary-golden flex justify-center items-center">
        {formatPrice(price)}
        </div>
    </div>

  
    <div className="app__menuitem-sub w-full mt-[0.2rem] flex">
      { [tags].flat().map(tag => getIcon(tag))}
    </div>
  </div>
);


export default MenuItem;
