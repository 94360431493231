import React from 'react';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding w-full relative z-1 flex justify-start items-center flex-col bg-primary-black app__bg pt-0 sm:px-0 sm:pb-8" id="contacto">
    <div className="app__footer-links w-full flex items-start flex-col justify-between lg:flex-row mt-20 py-0 px-8">

      <div className="app__footer-links_work flex-one my-8 mx-0 xl:m-4 text-left lg:text-center">
        <h1 className="app__footer-headtext font-CormorantUpright font-normal text-primary-white leading-10 tracking-wider capitalize text-size-3xl mb-4 big:text-[51px]">Horario</h1>
        <p className=" text-primary-crimson">Miércoles y domingo</p>
        <p className=" text-primary-golden font-bold">12:30 - 17:30</p>
        <p className=" text-primary-white">Jueves, viernes y sábado</p>
        <p className="text-primary-golden font-bold">12:30 - 17:30 | 20:30 - 00:00</p>
      </div>
    <div className="app__footer-links_work flex-one my-8 mx-0 xl:m-4 text-left lg:text-center">
        <h1 className="app__footer-headtext font-CormorantUpright font-normal text-primary-white leading-10 tracking-wider capitalize text-size-3xl mb-4 big:text-[51px]">Encuéntranos</h1>
        <p className=" text-primary-crimson">Dirección</p>
        <a href="https://maps.app.goo.gl/PXvBCxg35CxVoq7f9"><p className=" text-primary-golden font-bold">C/ Vista Alegre, 27, 28210 Valdemorillo (Madrid)</p>
          </a>
        <p className=" text-primary-crimson">Teléfono</p>
        <p className=" text-primary-golden font-bold">613 08 09 34</p>
        <p className=" text-primary-white">Correo electrónico</p>
        <a href="mailto:info@jimenavaldemorillo.es"><p className="text-primary-golden font-bold">info@jimenavaldemorillo.es</p></a>
      </div>
      <div className="app__footer-links_logo flex-one my-8 mx-0 xl:m-4 text-left lg:text-center">
        <img src={images.logo} alt="footer_logo" className='w-[150%] mb-3 lg:mx-auto'/>
        <p className="p__opensans lg:text-center text-left">&quot;Cocina de mercado rebosante de cariño.&quot;</p>
<a href="https://datasolution.es">        <img src={images.powered_by}  className='w-4/5 mobile:w-[40%] mb-3 lg:mx-auto' style={{ marginTop: 15 }} />
</a>
      </div> 
 


    </div>
  </div>
);

export default Footer;
