import spoon from '../assets/spoon.png'
import logo from '../assets/logo_jimena_white.svg';
import wine from '../assets/wine.png';
import tacos from '../assets/tacos.jpg';
import jimena_menu11 from '../assets/jimena_menu11.jpg';
import jimena_menu12 from '../assets/jimena_menu12.jpg';
import jimena_menu21 from '../assets/jimena_menu21.jpg';
import jimena_menu22 from '../assets/jimena_menu22.jpg';
import jimena_menu31 from '../assets/jimena_menu31.jpg';
import jimena_comedor from '../assets/jimena_comedor.jpg';
import jimena_logo from '../assets/jimena_logo.jpg';
import powered_by from '../assets/poweredby.png';
import bg from '../assets/bg.png';
import symbol from '../assets/jimena_symbol.svg';
export default {
  bg,
  symbol,
  spoon,
  logo,
  wine,
  tacos,
  jimena_menu11,
  jimena_menu12,
  jimena_menu21,
  jimena_menu22,
  jimena_menu31,
  jimena_comedor,
  jimena_logo,
  powered_by
};
