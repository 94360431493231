import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './MenuPicoteo.css';
import {Box} from '../../components';
const burger = data.burgers[0];
const halfSize = Math.ceil(data.picoteo.length / 2);
const dataArray = [data.picoteo.slice(0, halfSize), data.picoteo.slice(halfSize, data.picoteo.length)];

const MenuPicoteo = () => (
  <div className="flex-col bg-primary-black app__bg flex__center section__padding" id="picoteo">
    <div className="mb-8 m-auto flex flex-col justify-center items-center">
      <h1 className="headtext__cormorant">De picoteo</h1>
    </div>

    <div className="app__specialMenu-menu w-full mt-8 mx-0 flex justify-center items-center flex-col lg:items-start lg:flex-row">
      <div className="app__specialMenu-menu_food  flex__center flex-one w-full flex-col">
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {dataArray[0].map((food, index) => (
            <MenuItem key={food.title + index} title={food.title} price={food.price} tags={food.tags} />
          ))}
        </div>
        <Box title={burger.title} subtitle={burger.subtitle} price={burger.price} tags={burger.tags}/>

      </div>

      <div className="app__specialMenu-menu_img w-full mt-4 mb-12 mx-0 lg:w-[210px] lg:mx-8 lg:my-0 2xl:w-[450px] flex justify-center items-center">
            <div>

            </div>
      </div>

            
      <div className="app__specialMenu-menu_drinks  flex__center flex-one w-full flex-col">
        <div className="app__specialMenu_menu_items flex flex-col mx-0 my-8 w-full">
          {dataArray[1].map((ensala, index) => (
            <MenuItem key={ensala.title + index} title={ensala.title} price={ensala.price} tags={ensala.tags} />
          ))}
        </div>
        <SubHeading title="Bebida en terraza 10% de recargo."/>

      </div>
    </div>
  </div>
);

export default MenuPicoteo;
