import React, { useEffect, useState } from 'react';

import { images } from '../../constants';
import './AboutUs.css';



const getInstagramFeed = async () => {
  const instagramData = (await fetch('https://orsi.me/sniffagram/?user=donajimena_valdemorillo')).json();
  return instagramData;
}

const AboutUs = () => (
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.id = 'about';
    return () => {
      <div class="elfsight-app-a80fea14-5d77-48a2-bc45-b99544379d1b" data-elfsight-app-lazy></div>
    }
  })
  );


export default AboutUs;
