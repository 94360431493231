
const picoteo = [
  {
    title: 'Oreja a la plancha',
    price: 12,
    tags: [],
    featured: true,
  },
  {
    title: 'Torreznitos de Soria',
    price: 10,
    tags: [],
    featured: false,
  },
  {
    title: 'Bomboncitos de morcilla con salsa de arándanos',
    price: 12,
    tags: [],
    featured: false,
  },
  {
    title: 'Fish and chips de corvina con tártara de curry',
    price: 13,
    tags: [],
    featured: false,
  },
  {
    title: 'Provoletta con AOVE, tomate y orégano',
    price: 10,
    tags: [],
    featured: false,
  },
  {
    title: 'Huevos rotos con jamón ibérico',
    price: 18.50,
    tags: ['eggs'],
    featured: false,
  },
  {
    title: 'Boquerón de Cádiz a la andaluza',
    price: 12,
    tags: ['gluten', 'fish'],
    featured: false,
  },
  {
    title: 'Patatas bravas, ali oli o mixtas',
    price: 10,
    tags: ['gluten', 'eggs'],
    featured: false,
  },
  {
    title: 'Mejillones gallegos 1/2 kilo (vapor, bilbaína o salsa picante)',
    price: 12,
    tags: [],
    featured: true,
  },
  {
    title: 'Rejos de calamar',
    price: 11,
    tags: [],
    featured: true,
  },
  {
    title: 'Cazón en adobo',
    price: 10,
    tags: ['gluten', 'fish', 'sulphite'],
    featured: false,
  },
  {
    title: 'Tacos de cochinita pibil (2 uds)',
    price: 12,
    tags: ['gluten'],
    featured: true,
  },
];

const burgers = [{
  title: 'Hamburguesa Jimena',
  subtitle: '160 grs de Angus, cecina, tomate seco, cebolla encurtida, lechuga, mayonesa de Dijon y queso manchego semicurado de oveja',
  price: 13,
  tags: ['nuts', 'gluten', 'milk', 'mustard'],
  featured: false,
}];

const entradas = [
  {
    title: 'Croquetas caseras de Cecina de Buey y Jamón',
    price: 11,
    tags: ['gluten', 'milk', 'nuts'],
    featured: false,
  },
  {
    title: 'Alcachofas confitadas con cítricos y sal de jamón',
    price: 19,
    tags: [],
    featured: false,
  },
  {
    title: 'Mollejitas de lechal',
    price: 25,
    tags: [],
    featured: false,
  },
  {
    title: 'Callos a la madrileña',
    price: 14,
    tags: [],
    featured: false,
  },
  {
    title: 'Calamares de anzuelo a la plancha con ali oli de ajo negro',
    price: 18,
    tags: [],
    featured: false,
  },
  {
    title: 'Tempura de verduras de temporada',
    price: 14,
    tags: ['gluten'],
    featured: false,
  },
  {
    title: 'Atadillo de trigueros con tomate a la brasa y salsa de cabrales',
    price: 17,
    tags: [],
    featured: false,
  },
  {
    title: 'Anchoas de Santoña doble cero con su pan tostado y su tomate',
    price: 19,
    tags: ['fish'],
    featured: false,
  },
];

const ensaladas = [
  {
    title: 'Burratina (burrata con pesto de pistacho y rúcula salvaje)',
    price: 16,
    tags: [],
    featured: false,
  },
  {
    title: 'Tartar de tomate y aguacate',
    price: 13,
    tags: [],
    featured: false,
  },
  {
    title: 'Ensalada templada de perdiz escabechada',
    price: 18,
    tags: ['nuts', 'ex'],
    featured: false,
  },
  {
    title: 'Pimientos asados con tronco de bonito',
    price: 16,
    tags: ['fish'],
    featured: false,
  },
];

const deCuchara = [
  {
    title: 'Judiones de La Granja con su acompañamiento',
    price: 16,
    tags: [],
    featured: false,
  },
  {
    title: 'Sopa Castellana',
    price: 10,
    tags: ['gluten', 'eggs'],
    featured: false,
  },
];

const carnes = [
  {
    title: 'Rabo de ternera "La Ceci"',
    price: 20,
    tags: [],
    featured: false,
  },
  {
    title: 'Carrilleras estofadas al Oporto con un toquecito de chocolate Valor',
    price: 19,
    tags: [],
    featured: false,
  },
  {
    title: 'Chuletón de vaca a la parrilla (maduración 30 días) al peso (precio por kg)',
    price: 50,
    tags: [],
    featured: false,
  },
  {
    title: 'Entrecot de ternera de la Sierra de Guadarrama',
    price: 20,
    tags: [],
    featured: false,
  },
  {
    title: 'Chuletitas de lechal',
    price: 20,
    tags: [],
    featured: false,
  },
  {
    title: 'Solomillo Perigourdini',
    price: 24,
    tags: [],
    featured: false,
  },
  {
    title: 'Paletilla de lechazo de Riaza (por encargo)',
    price: 25,
    tags: [],
    featured: false,
  },
];
// del mar
const pescados = [
  {
    title: 'Tartar de atún rojo',
    price: 21,
    tags: ['fish'],
    featured: false,
  },
  {
    title: 'Bacalao al pil pil o encebollado',
    price: 22,
    tags: ['fish'],
    featured: false,
  },
  {
    title: 'Corvina a la Meuniere con mantequilla tostada',
    price: 19,
    tags: ['fish'],
    featured: false,
  },
  {
    title: 'Pitxin a la parrilla con bilbaina suave (s.m.)',
    price: 24,
    tags: ['fish'],
    featured: false,
  },
  {
    title: 'Tataki de atún rojo con salsa teriyaki',
    price: 24,
    tags: ['fish', 'ex', 'soya'],
    featured: false,
  },
  {
    title: 'Chipis encebollados',
    price: 17,
    tags: ['fish'],
    featured: false,
  },
];

const especiales = [
  {
    title: 'Costilla de ternera asada a baja temperatura con miel y tomillo',
    price: 19,
    tags: ['mustard', 'eggs'],
    featured: false,
  },
  {
    title: 'Steak Tartar Jimena',
    price: 22,
    tags: ['mustard', 'eggs'],
    featured: false,
  },
  {
    title: 'Pluma Duroc a la parrilla con salsa de guayaba y lima',
    price: 17,
    tags: [],
    featured: false,
  },
];

const especialesMar = [{
  title: 'Pulpo a la parrilla con AOVE de pimentón de la Vera',
  price: 29,
  tags: ['fish'],
  featured: false,
}, {
  title: 'Zamburiñas plancha con picadita de ajo y perejil',
  price: 19,
  tags: ['fish'],
  featured: false,
}]

const postres = [
  {
    title: 'Brownie con helado de vainilla',
    price: 7,
    tags: [],
    featured: false,
  },
  {
    title: 'Tiramisú de dulce de leche',
    price: 7,
    tags: [],
    featured: false,
  },
  {
    title: 'Tarta de zanahoria',
    price: 7,
    tags: ['gluten', 'milk', 'nuts'],
    featured: false,
  },
  {
    title: 'New York Cheese Cake',
    price: 7,
    tags: ['gluten', 'milk', 'nuts'],
    featured: false,
  },
  {
    title: 'Coulant de chocolate',
    price: 7,
    tags: ['gluten', 'milk', 'nuts'],
    featured: false,
  },
  {
    title: 'Apple Strudel con helado de pistacho',
    price: 8,
    tags: ['gluten', 'milk', 'nuts'],
    featured: false,
  },

  {
    title: 'Arroz con leche',
    price: 6,
    tags: ['milk'],
    featured: false,
  },
  {
    title: 'Helados variados',
    price: 4,
    tags: ['milk'],
    featured: false,
  },
];

const vinos = {
  tintos: [
    {
      brand: "D.O. Ribera",
      wines: [{
        name: "Roble Carramimbre",
        prices: [2.90, 16],
      }, { name: "Cosecha Finca Resalso (Emilio Moro)", prices: [2.90, 16] }, { name: "Crianza 4 Caminos", prices: [null, 19] }, { name: "Crianza Finca El Empecinado", prices: [null, 19] }, { name: "Reserva Protos", prices: [null, 36] }]
    },
    {
      brand: "D.O. Rioja",
      wines: [{
        name: "Crianza Heredad Sodupe",
        prices: [2.90, 15],
      },
      { name: "Crianza LAN", prices: [null, 19] }, { name: "Reserva Marqués de Riscal", prices: [null, 32] }, {name: "Reserva Luis Cañas", prices: [null, 32]}]
    },

  ],
  blancos: [
    {
      brand: "D.O. Rueda",
      wines: [{ name: "Viña 65 Verdejo", prices: [2.40, 15] }]
    },
    {
      brand: "D.O. Rías Baixas",
      wines: [{ name: "Camiño do Rey Albariño", prices: [2.70, 16] }, { name: "Coral Do Mar Albariño", prices: [null, 17] }]
    },
    {
      brand: "D.O Monterrei",
      wines: [{ name: "Godello Ladairo con Treixadura colección de la familia", prices: [3, 20] }]
    }
  ],
  rosados: [
    {
      brand: "D.O Somontano",
      wines: [{ name: "3404 Rosado Pálido Bodegas Pirineos", prices: [2.70, 16] }]
    },
  ],
  semidulces: [
    {
      brand: "Pedro Escudero",
      wines: [{ name: "Petit Loco Haciendo Vino", prices: [2.50, 15] }]
    },
  ]
}

const dishes = { picoteo, entradas, burgers, ensaladas, deCuchara, carnes, pescados, especiales, especialesMar, postres, vinos };
export default dishes;