import React, { useEffect } from 'react';
import './Gallery.css';

const Gallery = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (<>
    <blockquote
      className="instagram-media flex-col"
      data-instgrm-permalink="https://www.instagram.com/donajimena_valdemorillo/"
      data-instgrm-version={12}
      style={{
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
        paddingLeft: "100px",
        width: "100%",
        maxHeight: "170vh",
      }}
    >
    </blockquote>
    <style
      dangerouslySetInnerHTML={{
        __html:
          ".boxes3{height:175px;width:153px;} #n img{max-height:none!important;max-width:none!important;background:none!important} #inst i{max-height:none!important;max-width:none!important;background:none!important}"
      }}
    />
  </>
    );
};

export default Gallery;
